
import { Options, Vue } from 'vue-class-component';
import { CartClient, OrderClient, NexiClient, CommunityClient } from '@/services/Services';
import { baseUrl } from '@/config';
import SelectCityModal from '@/components/selectCityModal.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import CartItem from '@/components/cartItem.vue';
import ShipmentDetailModal from './modals/shipmentDetailModal.vue';
import * as OM from '@/model';
import { loadScript } from "vue-plugin-load-script";
import { LocalizationServices } from '@/services/LocalizationServices';


@Options({
    components: {
        CartItem,
    }
})
export default class CartResume extends Vue {

    loaded: boolean = false;
    communityIdentifier: string = "";
    buttonDisabled: boolean = true;
    model: OM.CartVM = new OM.CartVM();

    nexiClientModel: OM.NexiClientModel = new OM.NexiClientModel();
    paymentRequest: OM.NexiPaymentRequest = new OM.NexiPaymentRequest();

    card: any = null;
    buttons: any = null;
    errors: any = "";
    
    paymentLoading: boolean = true;
    slug: string = "";

    created() {
    }

    get isDisabled(){
        if(!this.hasShippableItem)
            return false;

       return this.model.shippingAddress == null || !this.model.shippingAddress.typedAddress;
    }

    async mounted() {
        this.slug = this.$route.params.slug.toString();
        await CommunityClient.getBySlug(this.slug)
        .then(x => {
            this.communityIdentifier = x.identifier;
        })
        this.init();       
    }

    init() {
        CartClient.getCartDetail(this.communityIdentifier)
        .then(x => {
            this.model = x;

            console.log(x)

            if(this.model.xPayApiKey){

                loadScript("https://" + process.env.VUE_APP_XPAY + ".nexi.it/ecomm/XPayBuild/js?alias=" + this.model.xPayApiKey)
                .then(() => {

                    var paymentRequestVM = new OM.PaymentRequestVm();
                    paymentRequestVM.orderIdentifier = this.model.cartIdentifier;
                    paymentRequestVM.amount = this.model.totalPrice;

                    NexiClient.getPaymentModel(paymentRequestVM)
                    .then(y => {
                        this.nexiClientModel = y;
                        // Inizializzazione SDK e configurazione del pagamento
                        XPay.init();

                        var xPayEnvironment;
                        if(process.env.VUE_APP_ENVIRONMENT == "TEST")
                            xPayEnvironment = XPay.Environments.INTEG;
                        else
                            xPayEnvironment = XPay.Environments.PROD;

                        var config = {
                            baseConfig: {
                                apiKey: this.model.xPayApiKey,
                                enviroment: xPayEnvironment
                            },
                            paymentParams: {
                                amount: y.amount,
                                transactionId: y.transactionCode,
                                currency: y.currency,
                                timeStamp: y.timeStamp,
                                mac: y.mac,
                                url: baseUrl + 'api/Nexi/Pay',
                                urlBack: y.urlBack,
                            },
                            customParams: {},
                            language: XPay.LANGUAGE.ITA,
                            // serviceType: "paga_oc3d",
                            // requestType: "PP"
                        };

                        // Configurazione SDK
                        XPay.setConfig(config);
                        // 3D Secure 2.0
                        XPay.setInformazioniSicurezza({});

                        // Configurazione dello stile per il form dei dati carta
                        var style = {
                            common: {
                                color: "#252631",
                                fontFamily: "Arial",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontVariant: "normal",
                                letterSpacing: "normal",
                                textDecoration: "none currentcolor solid",
                                textShadow: "none",
                            },
                            correct: {
                                color: "#12AA12",
                                fontFamily: "Arial",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontVariant: "normal",
                                letterSpacing: "normal",
                                textDecoration: "none currentcolor solid",
                                textShadow: "none",
                            },
                            error: {
                                color: "#f50808",
                                fontFamily: "Arial",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontVariant: "normal",
                                letterSpacing: "normal",
                                textDecoration: "none currentcolor solid",
                                textShadow: "none",
                            },
                        };
                        
                        // Handler per la gestione degli errori di validazione carta
                        window.addEventListener("XPay_Card_Error", this.paycardErrorCallback);
                        // Handler per ricevere nonce pagamento
                        window.addEventListener("XPay_Nonce", this.nonceCallback);
                        window.addEventListener("XPay_Ready", this.readyCallback);
                        window.addEventListener("XPay_Payment_Started", this.paymentStartedCallback);

                        // Creazione dell'elemento carta
                        var acceptedCards = [XPay.CardBrand.MASTERCARD, XPay.CardBrand.VISA];

                        this.card = XPay.create(XPay.OPERATION_TYPES.CARD, style, acceptedCards);
                        this.card.mount("xpay-card");

                        // Creazione dei bottoni per i metodi di pagamento disponibili per il proprio profilo
                        this.buttons = XPay.create(XPay.OPERATION_TYPES.PAYMENT_BUTTON);
                        this.buttons.mount("xpay-btn");

                        this.loaded = true;
                    })
                })


            }   
        })
    }

    openSettings() {
        this.$router.push("/communities/" + this.slug + "/Cart/settings");
    }

    paycardErrorCallback(event: any) {
        if (event.detail.errorMessage) {
            this.buttonDisabled = false;
            this.errors = event.detail.errorMessage;
        } else {
            this.errors = '';
        } 
    }
    nonceCallback(event: any) {
        var response = event.detail;

        if (response.esito && response.esito === "OK") {
            this.paymentRequest.xpayNonce = response.xpayNonce;
            this.paymentRequest.xpayIdOperazione = response.idOperazione;
            this.paymentRequest.xpayTimeStamp = response.timeStamp;
            this.paymentRequest.xpayEsito = response.esito;
            this.paymentRequest.xpayMac = response.mac;
            this.paymentRequest.importo = this.nexiClientModel.amount;
            this.paymentRequest.codiceTransazione = this.nexiClientModel.transactionCode;
            this.paymentRequest.divisa = this.nexiClientModel.currency;
            this.paymentRequest.orderIdentifier = this.model.cartIdentifier;
            
            this.pay();
        } else {
            this.errors = "[" + response.errore.codice + "] " + response.errore.messaggio;
            this.buttonDisabled = false;
        }
    }
    readyCallback(event: any) {
        // this.disabled = false;
        console.log('Build ' + event.detail + ' è caricato');
    }
    paymentStartedCallback(event: any) {
        console.log('Metodo selezionato dall\'utente: ' + event.detail);
    }

    shipmentDetail() {
        if(this.model.shipmentDescription) {
            this.$opModal.show(ShipmentDetailModal, {
                shipmentInfo: this.model.shipmentDescription
            })
        }
    }

    pay() {
        NexiClient.pay(this.paymentRequest)
        .then(x => {
            var message = LocalizationServices.getLocalizedValue("app_riceveraiabreveunaemaildiriepilogoedettagli", "Riceverai a breve una email di riepilogo, con i dettagli del pagamento appena effettuato");
            this.orderSuccess(message)
        })
    }

    orderSuccess(text: string){
        var title = LocalizationServices.getLocalizedValue("app_ordinecreatocorrettamentecart", "Ordine creato correttamente! ");
        var buttonText = LocalizationServices.getLocalizedValue("app_imieiordini", "I miei ordini");
        var message = title + text;
        this.$opModal.show(ModalSuccessError, {
            message: message,
            buttonText: buttonText,
            buttonUrl: "/order-list/"
        })
    }

    createOrderWireTranfer(){
        OrderClient.payWithWireTransfer(this.model.cartIdentifier)
        .then(x =>{
            var message = LocalizationServices.getLocalizedValue("app_riceveraiabreveunaemaildiriepilogo", "Riceverai a breve una email di riepilogo");
            this.orderSuccess(message)
            
        })
    }

    beforeDestroy() {
        window.removeEventListener("XPay_Card_Error", this.paycardErrorCallback);
        window.removeEventListener("XPay_Nonce", this.nonceCallback);
        window.removeEventListener("XPay_Ready", this.readyCallback);
        window.removeEventListener("XPay_Payment_Started", this.paymentStartedCallback);
    }

    pagaBtn() {
        // Handler per gestire click sul bottone paga (fa la chiamata per generare il nonce)
        this.paymentLoading = true;
        // Creazione del nonce
        XPay.createNonce("payment-form", this.card);
    }

    get hasShippableItem(){
        return this.model.cartItems.find( x => x.isShippable) != null
    }

    searchCity() {
        var modalTitle = LocalizationServices.getLocalizedValue("app_indirizzoSpedizione", "Indirizzo di spedizione");
        var question = LocalizationServices.getLocalizedValue("app_doveConsegnaMerce", "Dove vuoi che ti venga consegnata la merce?");

        this.$opModal.show(SelectCityModal, {
            addressTitle: modalTitle,
            addressQuestion: question,
            searchText: this.model.shippingAddress ? this.model.shippingAddress.typedAddress : "",
            callback: (city: OM.Address) => {
                var vm = new OM.SetAddressVM();
                vm.identifier = this.model.cartIdentifier;
                vm.shippingAddress = city;

                CartClient.setShippingAddress(vm)
                .then( x => {
                    this.model.shippingAddress = city;
                    this.$opModal.closeLast();
                }).catch( err => {
                    window.toastr.error("C'è stato un errore nel salvataggio dell'indirizzo di spedizione")
                })
            }
        })
    }
}

