import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "mt-5 mb-3" }
const _hoisted_3 = { class: "fs_14 d-block ln_12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", {
    class: "bottomModalContainer",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
  }, [
    _createVNode("div", {
      class: "bottomModal",
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent","stop"]))
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_dettaglioSpedizione",
          text: "Dettaglio spedizione",
          class: "greyBlue"
        }),
        _createVNode("img", {
          src: _imports_0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.shipmentInfo), 1)
      ])
    ])
  ]))
}