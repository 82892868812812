
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({})
export default class CartItem extends Vue {

    @Prop({
        default: () => new OM.CartItemVM
    }) model: OM.CartItemVM;

    created() {
    }

}

