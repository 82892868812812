
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ShipmentDetailModal extends Vue {

    @Prop({
        default: ""
    }) shipmentInfo: string;

    created() {}

}

